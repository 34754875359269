@use '@angular/material' as mat;
/* You can add global styles to this file, and also import other style files */
@import 'material-icons/iconfont/outlined.css';
@include mat.core();
@import 'variables';
@import 'spinner';
@import 'mat-calendar';


.nav-tabs .nav-link:not(.active):hover  {
  border-color: black;
}

.card {
  border-radius: 8px!important;
  box-shadow: none!important;
}

.navbar .header-with-tabs-for-desktop img {
  height: 2.5rem;
  margin: 0 0 0.75rem 0.75rem ;
}

.mat-icon-base-red {
  color: $base-red;

  .disabled-field & {
    opacity: 0.3;
  }
}

.mat-mdc-form-field-outline-start, .input {
  border-radius: 0.5rem 0 0 0.5rem !important;
  min-width: 1rem !important;
}

.mat-mdc-form-field-outline-end, .input {
  border-radius: 0 0.5rem 0.5rem 0 !important;
}

.mat-mdc-form-field-appearance-outline .mat-mdc-form-field-outline-thick {
  color: $base-red;
}

.mat-mdc-form-field-appearance-outline .mat-mdc-form-field-outline {
  color: #b7cbd3 !important;
  top: 0 !important;
}

/* Change the border focused color */
.mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: var(--color-primary) !important;
}

.forecast-activity .mat-mdc-text-field-wrapper {
  align-self: center;
  width:85% !important;
}

.forecast-activity .mdc-text-field--outlined .mdc-text-field__input {
  font-size: 0.875rem;
}

.forecast-activity .mat-mdc-form-field-infix {
  padding-top: 0.625rem !important;
  padding-bottom: 0.625rem !important;
  min-height: 1.25rem !important;
}

#cycle-length .mat-mdc-icon-button.mat-mdc-button-base {        
  width: 32px;
  height: 32px; 
}

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }


