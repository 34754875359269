.mat-calendar-body-selected {
  background-color: $base-red;
  color: #fff;
}

@media (hover: hover) {
.mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover
> .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: $base-red;
  opacity: 0.2;
}
}
